@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

.ml-1
{
  margin-left: 1em;
}
.right{
  float: right;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bk-bisque
{
  background-color: bisque;
}

.bk-red
{
  background-color: red;
}
.mr-1
{
  margin-right: 1em;
}
.menu.selected
{
  color: red;
}
.white
{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
.black
{
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
.scrollable
{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  width: 100%;
}



.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  z-index: 2;
}


#customer-commander
{
 /*  background-color: red; */
  border-radius: 10px;
  height: 100%;
  
  
}
.ml-3
{
  margin-left: 5px;
}
.mt-3
{
 margin-top: 3em;
}

.mt-1
{
 margin-top: 1em;
}

.mb-1
{
 margin-bottom: 1em;
}

.hidden
{
  display: none;
}
small
{
color: red;
}
.full-width
{
  width:100%;
}

.left
{
  float: left;
  
}

.margin-10
{
  margin: 10px;
}

.top-10
{
  
  padding-top: 10px;
}

.create-booking
{
  border-radius: 20px;
  background-color: lightgrey;
  margin: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 70px;
}

.MenuItem{
  color: black;
  padding: 10px;
}
.MenuItem:hover
{
  background-color:lightgray;
  cursor: pointer;
}
a:link
{
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 iframe {
  display: block;
  background: #000;
  border: none;
  height: calc(100vh - 140px);
  width: 100%;
}

.full-width-height
{
  display: block;
  background: #000;
  border: none;
  height: calc(100vh - 120px);
  width: 100%;

}

